import React from 'react';

const FontLoader = ({ fontFamily }) => {
  React.useEffect(() => {
    // Skip if it's a custom font
    if (fontFamily.includes(' ')) {
      const link = document.createElement('link');
      const cleanFontName = fontFamily.replace(/ /g, '+');
      link.href = `https://fonts.googleapis.com/css2?family=${cleanFontName}:wght@400;700&display=swap`;
      link.rel = 'stylesheet';
      
      // Add loading state attribute
      link.setAttribute('data-loading', 'true');
      
      // Handle successful load
      link.onload = () => {
        link.removeAttribute('data-loading');
      };
      
      // Handle load failure
      link.onerror = () => {
        console.error(`Failed to load font: ${fontFamily}`);
        document.head.removeChild(link);
      };
      
      document.head.appendChild(link);
      
      return () => {
        const links = document.head.querySelectorAll('link');
        links.forEach(existingLink => {
          if (existingLink.href === link.href) {
            document.head.removeChild(existingLink);
          }
        });
      };
    }
  }, [fontFamily]);

  return null;
};

export default FontLoader;