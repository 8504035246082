import React, { useRef, useState, useEffect } from 'react';
import { ChevronDown, Download, Share2, Copy, Check } from 'lucide-react';
import { popularFonts, typeScales, headingSizes, loadCustomFont, generateColorScale } from './utils';

const Configurator = ({
  typeScale,
  setTypeScale,
  fontFamily,
  setFontFamily,
  h1Size,
  setH1Size,
  customFont,
  setCustomFont,
  onChange,
  onSave,
  isSaving,
  isReadOnly,
  shareUrl,
  colors
}) => {
  const fileInputRef = useRef(null);
  const [copyState, setCopyState] = useState('default'); // 'default' | 'success'
  const [fileSizes, setFileSizes] = useState({ config: 0, css: 0 });
  const [generatedConfig, setGeneratedConfig] = useState({ config: '', css: '' });
  // Generate configuration whenever inputs change
  useEffect(() => {
    const generateConfig = () => {
      // Calculate the type scale based on the selected ratio and h1 size
      const calculateTypeScale = (baseSize, ratio) => {
        const scale = {};
        const baseRem = parseFloat(baseSize.match(/\d+\.?\d*/)[0]);

        // Generate smaller sizes
        for (let i = 0; i < 3; i++) {
          const size = baseRem / Math.pow(ratio, i + 1);
          scale[`sm${i + 1}`] = `${size.toFixed(3)}rem`;
        }

        // Base size
        scale.base = '1rem';

        // Generate larger sizes
        for (let i = 0; i < 6; i++) {
          const size = baseRem * Math.pow(ratio, i);
          scale[`${i + 1}xl`] = `${size.toFixed(3)}rem`;
        }

        return scale;
      };

      // Generate color configuration
      const generateColorConfig = () => {
        const colorConfig = {};
        const cssColorVars = [];

        if (Array.isArray(colors)) {
          colors.forEach(color => {
            // Ensure we have valid color data
            if (!color?.hex) return;

            // Create a valid CSS/Tailwind class name from the color name
            const colorKey = color.name
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '-') // Replace non-alphanumeric chars with hyphens
              .replace(/-+/g, '-')         // Replace multiple hyphens with single hyphen
              .replace(/^-|-$/g, '');      // Remove leading/trailing hyphens

            // Generate the complete color scale
            const scale = generateColorScale(color.hex);

            // Add to Tailwind config using the color name
            colorConfig[colorKey] = scale;

            // Add CSS custom properties using the color name
            Object.entries(scale).forEach(([shade, value]) => {
              cssColorVars.push(`  --color-${colorKey}-${shade}: ${value};`);
            });
          });
        }

        return { colorConfig, cssColorVars };
      };

      // Generate font family configuration
      const generateFontConfig = () => {
        const fonts = {
          sans: [`'${fontFamily}'`, 'sans-serif'],
        };

        if (customFont) {
          fonts.custom = [`'${customFont}'`, 'sans-serif'];
        }

        return fonts;
      };

      const typeScaleRatio = {
        'minor-second': 1.067,
        'major-second': 1.125,
        'minor-third': 1.2,
        'major-third': 1.25,
        'perfect-fourth': 1.333,
        'augmented-fourth': 1.414,
        'perfect-fifth': 1.5,
      }[typeScale];

      const fontSize = calculateTypeScale(h1Size, typeScaleRatio);
      const fontConfig = generateFontConfig();
      const { colorConfig, cssColorVars } = generateColorConfig();

      console.log('Generated Color Config:', colorConfig);

      const tailwindConfig = `
module.exports = {
  theme: {
    extend: {
      colors: ${JSON.stringify(colorConfig, null, 6)},
      fontSize: ${JSON.stringify(fontSize, null, 6)},
      fontFamily: ${JSON.stringify(fontConfig, null, 6)},
      lineHeight: {
        'tight': '1.15',
        'snug': '1.3',
        'normal': '1.5',
        'relaxed': '1.625',
        'loose': '2',
      },
    },
  },
  plugins: [],
}`;

      console.log('Generated Color Config:', colorConfig);

      const cssConfig = `
/* Tailwind Typography and Color Configuration */
${customFont ? `
@font-face {
  font-family: '${customFont}';
  src: local('${customFont}');
}
` : ''}
${fontFamily !== 'system-ui' ? `
@import url('https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}:wght@400;700&display=swap');
` : ''}

:root {
  /* Color System */
${cssColorVars.join('\n')}

  /* Typography */
  --font-sans: ${fontFamily}, system-ui, sans-serif;
  ${customFont ? `--font-custom: ${customFont}, system-ui, sans-serif;` : ''}
}

/* Base Styles */
html {
  font-family: var(--font-sans);
  line-height: 1.5;
}

/* Type Scale */
.text-sm { font-size: ${fontSize.sm1}; }
.text-base { font-size: ${fontSize.base}; }
.text-lg { font-size: ${fontSize['1xl']}; }
.text-xl { font-size: ${fontSize['2xl']}; }
.text-2xl { font-size: ${fontSize['3xl']}; }
.text-3xl { font-size: ${fontSize['4xl']}; }
.text-4xl { font-size: ${fontSize['5xl']}; }
.text-5xl { font-size: ${fontSize['6xl']}; }

/* Color Utilities */
${Object.keys(colorConfig).map(colorName => `
.bg-${colorName} { background-color: var(--color-${colorName}-500); }
.text-${colorName} { color: var(--color-${colorName}-500); }
.border-${colorName} { border-color: var(--color-${colorName}-500); }
`).join('\n')}
`;

      return {
        config: tailwindConfig.trim(),
        css: cssConfig.trim()
      };
    };

    const config = generateConfig();
    setGeneratedConfig(config);

    // Calculate file sizes
    const configSize = new Blob([config.config]).size;
    const cssSize = new Blob([config.css]).size;
    setFileSizes({ config: configSize, css: cssSize });

    // Notify parent of changes
    onChange?.(config);
  }, [typeScale, fontFamily, h1Size, customFont, colors, onChange]);
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
  };

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopyState('success');
      setTimeout(() => setCopyState('default'), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  const handleFontUpload = async (event) => {
    if (isReadOnly) return;

    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.includes('font')) {
      alert('Please upload a valid font file (ttf, otf, woff, woff2)');
      return;
    }

    try {
      const fontName = file.name.replace(/\.[^/.]+$/, "");
      await loadCustomFont(file, fontName);
      setCustomFont(fontName);
      onChange();
    } catch (error) {
      console.error('Failed to load font:', error);
      alert('Failed to load font. Please try another file.');
    }
  };

  const downloadConfig = () => {
    const blob = new Blob([generatedConfig.config], { type: 'text/javascript' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'tailwind.config.js';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const downloadCSS = () => {
    const blob = new Blob([generatedConfig.css], { type: 'text/css' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'theme.css';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="space-y-3">
      {/* Typography Settings */}
      <section className="space-y-6 bg-[hsl(var(--surface))] rounded-xl p-8 pt-6">
        <h2 className="text-xl font-semibold mb-6">Configuration</h2>
        <div className="space-y-2">
          <label className="block text-sm font-medium">Type Scale</label>
          <div className="relative">
            <select
              value={typeScale}
              onChange={(e) => setTypeScale(e.target.value)}
              disabled={isReadOnly}
              className="w-full p-2 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 appearance-none pr-8 input-border bg-[hsl(var(--surface))] disabled:opacity-50"
            >
              {Object.entries(typeScales).map(([scale, ratio]) => (
                <option key={scale} value={scale}>
                  {scale.replace('-', ' ')} ({ratio})
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDown className="w-5 h-5 text-gray-500" />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">Google Font</label>
          <div className="relative">
            <select
              value={fontFamily}
              onChange={(e) => setFontFamily(e.target.value)}
              disabled={isReadOnly}
              className="w-full p-2 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 appearance-none pr-8 input-border bg-[hsl(var(--surface))] disabled:opacity-50"
            >
              {popularFonts.map(font => (
                <option key={font} value={font}>{font}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDown className="w-5 h-5 text-gray-500" />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">Custom Font</label>
          <p className="text-xs font-mono leading-5 opacity-50">
            Upload a custom font file (ttf, otf, woff, woff2). The font's name and <code>@font-face</code> rule will be included in the generated config files.
          </p>
          <div className="flex gap-2">
            <input
              type="file"
              ref={fileInputRef}
              accept=".ttf,.otf,.woff,.woff2"
              className="hidden"
              onChange={handleFontUpload}
              disabled={isReadOnly}
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              disabled={isReadOnly}
              className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white disabled:opacity-50 w-full"
            >
              Upload Font
            </button>
            {customFont && (
              <div className="flex items-center gap-2">
                <span className="text-sm">{customFont}</span>
                {!isReadOnly && (
                  <button
                    onClick={() => {
                      setCustomFont(null);
                      localStorage.removeItem('customFont');
                      onChange();
                    }}
                    className="text-red-500 hover:text-red-600"
                  >
                    Remove
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium">H1 Base Size</label>
          <div className="relative">
            <select
              value={h1Size}
              onChange={(e) => setH1Size(e.target.value)}
              disabled={isReadOnly}
              className="w-full p-2 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 appearance-none pr-8 input-border bg-[hsl(var(--surface))] disabled:opacity-50"
            >
              {Object.entries(headingSizes).map(([size, rem]) => (
                <option key={size} value={size}>
                  {size} ({rem}rem)
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDown className="w-5 h-5 text-gray-500" />
            </div>
          </div>
        </div>
      </section>

      {/* Download and Save Buttons */}
      <section className="space-y-4 p-8 pt-6 bg-[hsl(var(--surface))] rounded-xl">
        <h2 className="text-xl font-semibold mb-6">Download Generated Files</h2>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-4">
            <button
              onClick={downloadConfig}
              className="flex flex-col justify-center items-center gap-1 px-4 py-2 rounded-xl bg-[hsl(var(--surface))] hover:opacity-80 input-border w-full"
            >
              <div className="flex gap-2">
                <Download size={18} />
                <span>tailwind.config.js</span>
              </div>
              <span className="text-xs opacity-60">{formatFileSize(fileSizes.config)}</span>
            </button>
            <button
              onClick={downloadCSS}
              className="flex flex-col justify-center items-center gap-1 px-4 py-2 rounded-xl bg-[hsl(var(--surface))] hover:opacity-80 input-border w-full"
            >
              <div className="flex gap-2">
                <Download size={18} />
                <span>theme.css</span>
              </div>
              <span className="text-xs opacity-60">{formatFileSize(fileSizes.css)}</span>
            </button>
          </div>
          {!isReadOnly && onSave && (
            <button
              onClick={onSave}
              disabled={isSaving}
              className="flex flex-row justify-center items-center gap-2 px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white disabled:opacity-50"
            >
              <Share2 size={18} />
              <span>{isSaving ? 'Saving...' : 'Save & Share'}</span>
            </button>
          )}
        </div>

        {shareUrl && (
          <div className="mt-4 space-y-2">
            <div className="flex items-center justify-between gap-4 p-3 bg-[hsl(var(--surface))] rounded-md">
              <code className="text-sm truncate">{shareUrl}</code>
              <button
                onClick={handleCopyUrl}
                className={`flex-shrink-0 p-2 rounded ${copyState === 'success' ? 'bg-green-500 text-white' : 'hover:bg-black/10'}`}
                title="Copy URL"
              >
                {copyState === 'success' ? <Check size={18} /> : <Copy size={18} />}
              </button>
            </div>
            <p className="text-xs opacity-60">
              Share this URL to let others view and duplicate your configuration
            </p>
          </div>
        )}
      </section>
    </div>
  );
};

export default Configurator;
