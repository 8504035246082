import React, { useEffect, useState } from 'react';
import { ColorRole, generateColorScale, headingSizes, normalizeHex } from './utils';
import ErrorBoundary from './ErrorBoundary';
import FontLoader from './FontLoader';

const ColorPreview = ({ colors }) => {
  return (
    <div className="w-full space-y-8">
      {colors.map((color, colorIndex) => {
        const scale = generateColorScale(color.hex);
        return (
          <div key={colorIndex} className="w-full space-y-4">
            <div className="flex gap-3">
              <div
                className="w-6 h-6 rounded-full flex-shrink-0"
                style={{ backgroundColor: normalizeHex(color.hex) }}
              />
              <h4 className="font-medium">
                {color.name}
                {color.role !== ColorRole.NONE && (
                  <span className="ml-2 text-sm text-gray-500">({color.role})</span>
                )}
              </h4>
            </div>

            <div className="w-full grid grid-cols-9 gap-2">
              {Object.entries(scale).map(([shade, value]) => (
                <div key={shade} className="w-full">
                  <div
                    className={`
                      w-full aspect-square rounded shadow-sm
                      ${value.replace('#', '') === color.hex.replace('#', '') ? 'ring-2 ring-red-500 ring-offset-2' : ''}
                    `}
                    style={{ backgroundColor: value }}
                  />
                  <div className="text-xs mt-1 font-medium text-center">{shade}</div>
                  <div
                    className="text-xs font-mono truncate text-center"
                    title={value}
                  >
                    {value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TypePreview = ({ fontFamily, typeConfig, leadingValues = {}, onLeadingChange = () => {} }) => {
  const sampleText = "Pack my box with five brown liquor jugs";
  const paragraphText = "It suddenly struck me that that tiny pea, pretty and blue, was the Earth. I put up my thumb and shut one eye, and my thumb blotted out the planet Earth. I didn't feel like a giant. I felt very, very small.";

  // Define default sizes for each heading level
  const defaultSizes = {
    h1: 'text-4xl',
    h2: 'text-3xl',
    h3: 'text-2xl',
    h4: 'text-xl',
    h5: 'text-lg',
    h6: 'text-base'
  };

  // Ensure each heading level has a valid size
  const getSizeForTag = (tag) => {
    // Get the configured size or fall back to default
    const configSize = typeConfig?.[tag]?.size || defaultSizes[tag];
    
    let remValue;
    
    // Handle custom rem sizes
    if (configSize.startsWith('text-[')) {
      const match = configSize.match(/\[([\d.]+)rem/);
      remValue = match ? parseFloat(match[1]) : null;
    }
    
    // If no custom rem size, use headingSizes mapping
    if (!remValue && headingSizes[configSize]) {
      remValue = headingSizes[configSize];
    }
    
    // Final fallback
    return remValue || headingSizes[defaultSizes[tag]];
  }; // <-- Added closing bracket here

  const leadingOptions = {
    'leading-none': '1',
    'leading-tight': '1.25',
    'leading-snug': '1.375',
    'leading-normal': '1.5',
    'leading-relaxed': '1.625',
    'leading-loose': '2'
  };

  const defaultLeading = {
    h1: 'leading-normal',
    h2: 'leading-normal',
    h3: 'leading-normal',
    h4: 'leading-normal',
    h5: 'leading-normal',
    h6: 'leading-normal',
    body: 'leading-normal',
    ...leadingValues
  };

  const handleLeadingChange = (tag, value) => {
    onLeadingChange({
      ...defaultLeading,
      [tag]: value
    });
  };

  return (
    <div className="space-y-8" style={{ fontFamily: `'${fontFamily}', sans-serif` }}>
      {['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((tag) => {
        const size = getSizeForTag(tag);
        const leading = defaultLeading[tag];
        
        console.log(`Rendering ${tag}:`, {
          configuredSize: typeConfig?.[tag]?.size,
          calculatedSize: size,
          defaultSize: defaultSizes[tag]
        });

        return (
          <div key={tag} className="pb-6 border-b-modes last:border-0">
            <div className="text-sm text-gray-500 mb-2 font-mono">
              {tag.toUpperCase()} - {size}rem - {leading}
            </div>
            <div style={{ fontSize: `${size}rem` }} className={`font-bold tracking-tight ${leading}`}>
              {sampleText}
            </div>
            <div style={{ fontSize: `${size}rem` }} className={`font-normal tracking-tight ${leading}`}>
              {sampleText}
            </div>
            <div className="flex gap-1 mt-4 border-modes w-fit p-px rounded-full">
              {Object.entries(leadingOptions).map(([name]) => (
                <button
                  key={name}
                  onClick={() => handleLeadingChange(tag, name)}
                  className={`px-3 py-1 text-sm rounded-full transition-colors ${
                    leading === name
                      ? 'bg-blue-500 text-white'
                      : 'bg-[hsl(var(--surface))] hover:bg-gray-200 dark:hover:bg-slate-800'
                  }`}
                >
                  {name.replace('leading-', '')}
                </button>
              ))}
            </div>
          </div>
        );
      })}

      <div>
        <div className="text-sm text-gray-500 mb-2 font-mono">
          Body - text-base - {defaultLeading.body}
        </div>
        <p className={`w-2/3 text-base ${defaultLeading.body}`}>
          {paragraphText}
        </p>
        <div className="flex gap-1 mt-4 border-modes w-fit p-px rounded-full">
          {Object.entries(leadingOptions).map(([name]) => (
            <button
              key={name}
              onClick={() => handleLeadingChange('body', name)}
              className={`px-3 py-1 text-sm rounded-full transition-colors ${
                defaultLeading.body === name
                  ? 'bg-blue-500 text-white'
                  : 'bg-[hsl(var(--surface))] hover:bg-gray-200 dark:hover:bg-slate-800'
              }`}
            >
              {name.replace('leading-', '')}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const Preview = ({ colors, fontFamily, typeConfig, activeTab, leadingValues, setLeadingValues = () => {} }) => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey(prev => prev + 1);
    if (activeTab === 'colors') {
      setFontLoaded(true);
    } else {
      setFontLoaded(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab !== 'type') return;
    
    let timeoutId;
    const checkFontLoading = () => {
      // Compute the font name outside the template literal
      const processedFontName = fontFamily ? fontFamily.replace(/ /g, '+') : 'Inter';
      const link = document.querySelector(`link[href*="${processedFontName}"]`);
      if (link) {
        if (!link.hasAttribute('data-loading')) {
          setFontLoaded(true);
        } else {
          timeoutId = setTimeout(checkFontLoading, 100);
        }
      } else {
        setFontLoaded(true); // Fallback for system fonts
      }
    }; // <-- Added closing bracket here

    checkFontLoading();
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [fontFamily, activeTab]);

  return (
    <ErrorBoundary key={renderKey}>
      <div className="p-8 h-fit relative">
        {activeTab === 'type' && <FontLoader fontFamily={fontFamily || 'Inter'} />}
        
        {activeTab === 'type' && !fontLoaded && (
          <div className="absolute inset-0 bg-white/50 dark:bg-black/50 flex items-center justify-center">
            <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full" />
          </div>
        )}

        <div style={{ opacity: activeTab === 'type' ? (fontLoaded ? 1 : 0) : 1, transition: 'opacity 0.3s' }}>
          {activeTab === 'colors' && <ColorPreview colors={colors} />}
          {activeTab === 'type' && (
            <TypePreview
              key={`type-preview-${fontFamily}`}
              fontFamily={fontFamily || 'Inter'}
              typeConfig={typeConfig}
              leadingValues={leadingValues}
              onLeadingChange={setLeadingValues}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Preview;